var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full"},[_c('MapboxMap',{ref:"map",attrs:{"mapId":"map","projection":{
      name: 'globe',
    },"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle,"longitude":_vm.camera.longitude,"latitude":_vm.camera.latitude,"zoom":_vm.camera.zoom,"pitch":_vm.camera.pitch,"bearing":_vm.camera.bearing,"bounds":_vm.bounds,"padding":_vm.padding,"minZoom":_vm.minZoom,"maxZoom":_vm.maxZoom,"cameraOptions":{animate: false},"fitBoundsOptions":{animate: false},"logoPosition":"bottom-right"},on:{"camera":function($event){return _vm.onCameraChange($event)},"bounds":function($event){return _vm.onBoundsChange($event)},"click":function($event){return _vm.onClick($event)},"mousemove":function($event){return _vm.onHover($event)},"mouseout":function($event){return _vm.onLeave($event)},"touchstart":function($event){return _vm.onTouch($event)},"touchend":function($event){return _vm.onTouch($event)},"load":function($event){return _vm.onLoad($event)},"idle":function($event){return _vm.onIdle($event)},"resize":function($event){return _vm.onResize($event)}}},[_c('FlightLayer',{on:{"loaded":function($event){return _vm.onFlightsLoad($event)}}}),_c('ShipLayer',{on:{"loaded":function($event){return _vm.onShipsLoad($event)}}}),_c('StationLayer',{on:{"loaded":function($event){return _vm.onStationsLoad($event)}}}),(
        _vm.isSelected &&
          (_vm.selected.layer === 'flights' || _vm.selected.layer === 'ships')
      )?_c('PathLayer'):_vm._e(),(
        _vm.isSelected &&
          (_vm.selected.layer === 'flights' || _vm.selected.layer === 'ships')
      )?_c('PositionLayer'):_vm._e(),(_vm.isSelected && _vm.showZone && _vm.selected.layer === 'stations')?_c('ZoneLayer'):_vm._e(),_c('WindFarmLayer'),(_vm.isHovered && !_vm.touch)?_c('GeoTooltip',{attrs:{"coordinates":_vm.coordinates}},[(_vm.getHovered.layer === 'flights')?_c('FlightTooltip',{attrs:{"hovered":_vm.getHovered}}):_vm._e(),(_vm.getHovered.layer === 'position')?_c('PositionTooltip',{attrs:{"hovered":_vm.getHovered}}):_vm._e(),(_vm.getHovered.layer === 'ships')?_c('ShipTooltip',{attrs:{"hovered":_vm.getHovered}}):_vm._e(),(_vm.getHovered.layer === 'stations')?_c('StationTooltip',{attrs:{"hovered":_vm.getHovered}}):_vm._e()],1):_vm._e(),(_vm.isSelected)?_c('SideBar'):_vm._e(),_c('SearchBar'),(_vm.isModalOpen)?_c('Modal'):_vm._e(),_c('div',{staticClass:"ctrl-container"},[_c('NavigationControl',{staticClass:"m-2 rounded",attrs:{"visualizePitch":true}}),_c('GeolocateControl',{staticClass:"m-2 rounded",attrs:{"trackUserLocation":true},on:{"geolocate":function($event){return _vm.onGeolocate($event)},"trackuserlocationstart":function($event){return _vm.onTrack($event)},"trackuserlocationend":function($event){return _vm.onTrack($event)}}}),_c('FullscreenControl',{staticClass:"m-2 rounded"})],1),_c('MapboxImage',{attrs:{"id":"HLCPT","src":_vm.helicopter,"options":{sdf: true, pixelRatio: 8}}}),_c('MapboxImage',{attrs:{"id":"pulse","src":_vm.pulse}}),(_vm.track && _vm.geolocation)?_c('MapboxMarker',{attrs:{"lngLat":_vm.geolocation}},[_c('GeolocateIcon',{staticClass:"text-blue-500"})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }