<template>
  <MapboxSource
    :sourceId="id"
    type="geojson"
    attribution="&copy; ADS-B Network"
    :data="data"
  >
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="fill"
      :paint="zonePaint"
      beforeId="ships"
    />
    <MapboxLayer
      layerId="windfarm-outline"
      :sourceId="id"
      type="line"
      :layout="outlineLayout"
      :paint="oulinePaint"
      beforeId="ships"
    />
  </MapboxSource>
</template>

<script>
import data from "../assets/data/offshore-windfarm.json";

export default {
  name: "WindFarmLayer",
  data() {
    return {
      id: "windfarm",
      data,
      zonePaint: {
        "fill-color": [
          "case",
          ["==", ["get", "name"], "Saint-Nazaire-In"],
          "#3355FF",
          ["==", ["get", "name"], "Saint-Nazaire-Out"],
          "#002AFE",
          "#3355FF",
        ],
        "fill-opacity": 0.5,
      },
      outlineLayout: {
        "line-cap": "round",
        "line-join": "round",
      },
      oulinePaint: {
        "line-color": "#ffffff",
      },
    };
  },
};
</script>

<style></style>
