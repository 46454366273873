<template>
  <MapboxSource
    :sourceId="id"
    type="geojson"
    attribution="&copy; ADS-B Network"
    :data="zones"
  >
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="fill"
      :layout="zoneLayout"
      :paint="zonePaint"
      beforeId="flights"
    />
  </MapboxSource>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { feature } from "@turf/helpers";

export default {
  name: "ZoneLayer",
  computed: {
    ...mapGetters(["zones", "getSelected"]),
  },
  data() {
    return {
      id: "zone",
      station: {},
      zoneLayout: {
        "fill-sort-key": ["-", ["get", "altmin"]],
      },
      zonePaint: {
        "fill-color": [
          "case",
          ["==", ["get", "id"], "zone1"],
          "#4A148C",
          ["==", ["get", "id"], "zone2"],
          "#7B1FA2",
          ["==", ["get", "id"], "zone3"],
          "#9C27B0",
          ["==", ["get", "id"], "zone4"],
          "#BA68C8",
          "#000000",
        ],
        "fill-opacity": 0.5,
      },
    };
  },
  async mounted() {
    await this.fetchZones(this.getSelected.id);
    let { coordinates, id } = this.getSelected;
    this.station = feature({ type: "Point", coordinates }, { id });
  },
  methods: {
    ...mapActions(["fetchZones"]),
  },
};
</script>

<style>
</style>