<template>
  <div class="flex items-center">
    <span v-if="this.hovered.speed" class="flex items-center mr-2">
      <SpeedometerIcon class="w-4 mr-1" />
      {{ this.hovered.speed.toFixed(2) || "N/A" }} kts
    </span>
    <span v-if="this.hovered.altitude" class="flex items-center mr-2">
      <AltimeterIcon class="w-4 mr-1" />
      {{ this.hovered.altitude || "N/A" }} ft
    </span>
    <span v-if="this.hovered.timestamp" class="flex items-center mr-2">
      <TimeIcon class="w-4 mr-1" />
      {{ date || "N/A" }}
    </span>
  </div>
</template>

<script>
import SpeedometerIcon from "../assets/icons/speedometer.svg";
import AltimeterIcon from "../assets/icons/altimeter.svg";
import TimeIcon from "../assets/icons/time.svg";
import { timestampToDate } from "../utils/convert";

export default {
  name: "PositionTooltip",
  components: {
    SpeedometerIcon,
    AltimeterIcon,
    TimeIcon,
  },
  props: {
    hovered: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      return timestampToDate(this.hovered.timestamp);
    },
  },
};
</script>

<style></style>
