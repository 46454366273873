import Vue from "vue";
import Router from "vue-router";
import Map from "./components/Map.vue";
// import SignUp from "./components/SignUp.vue";
// import SignIn from "./components/SignIn.vue";
import Home from "./components/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/home",
      beforeEnter: (to, from, next) => {
        if (
          localStorage.getItem("token") === null ||
          localStorage.getItem("token") === undefined
        ) {
          next("/login");
        } else {
          next();
        }
      },
      component: Home,
    },
    // { path: "/signin", name: "signin", component: SignIn },
    // { path: "/signup", name: "signup", component: SignUp },
    {
      path: "/",
      name: "map",
      component: Map,
      children: [
        { path: ":zoom/:latitude/:longitude", name: "coords", component: Map },
        { path: "flights/:id", name: "flights", component: Map },
        { path: "ships/:id", name: "ships", component: Map },
        { path: "stations/:id", name: "stations", component: Map },
      ],
    },
  ],
});
