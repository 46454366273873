import Vue from 'vue';
import VueMapbox from '@adsbnetwork/vue-mapbox-gl';
import App from './App.vue';
import store from './store';
import router from './router';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/styles/tailwind.css';

Vue.config.productionTip = false;
Vue.use(VueMapbox);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
