<template>
  <div
    class="absolute top-0 right-0 z-30 flex flex-col items-end w-full p-2 sm:w-80"
  >
    <div
      class="flex items-center justify-between w-full bg-white rounded shadow"
      :class="{ 'mb-2': isResultFound }"
    >
      <input
        type="text"
        :value="search"
        @input="onSearch($event)"
        class="flex-1 px-4 py-3 text-sm leading-tight text-gray-900 placeholder-gray-500 rounded focus:outline-none"
        placeholder="Search..."
        aria-label="Search"
      />
      <button
        class="flex items-center justify-center px-4 cursor-pointer"
        :class="{ 'pointer-events-none': !isSearching }"
        @click="onFilter($event)"
        title="Filter on the map"
        aria-label="Filter on the map"
      >
        <FilterIcon
          :class="{ 'text-blue-500': filter, 'text-gray-500': !isSearching }"
        />
      </button>
    </div>
    <ul
      v-show="search.length > 0 && results.length > 0"
      class="w-full h-48 px-4 overflow-y-auto bg-white rounded shadow"
    >
      <li
        @click="onClick(result)"
        class="my-3 cursor-pointer"
        v-for="result in results"
        :key="result.id"
      >
        <div v-if="result.layer === 'flights'" class="flex items-center">
          <component :is="getIcon(result.icon)" class="w-10 h-10" />
          <div class="flex items-start w-full">
            <div class="flex flex-col flex-1 pl-3">
              <div class="text-base text-gray-900">
                <span class="mr-1">{{ result.id }}</span>
                <span v-if="result.usage">({{ result.usage }})</span>
              </div>
              <div class="flex text-xs text-gray-500">
                <span class="mr-1">{{ result.callsign || "N/A" }}</span>
                <span>{{ result.reg || "N/A" }}</span>
              </div>
              <div class="flex text-xs text-gray-500">
                <span class="mr-1">{{ result.icon }}</span>
                <span class="mr-1">{{ result.typeIcao || "N/A" }}</span>
              </div>
            </div>
            <div
              class="flex flex-col items-start flex-1 pl-3"
              v-if="result.opIcao"
            >
              <div class="text-base text-gray-900">{{ result.opIcao }}</div>
              <div class="flex justify-between w-full text-xs text-gray-500">
                <span>{{ result.fromIcao || "N/A" }}</span>
                <span>&rarr;</span>
                <span>{{ result.toIcao || "N/A" }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="result.layer === 'ships'" class="flex items-center">
          <component :is="getIcon('BOAT')" class="w-10 h-10" />
          <div class="flex flex-col pl-3">
            <div class="text-base text-gray-900">{{ result.id }}</div>
            <div class="flex text-xs text-gray-500">
              <span class="mr-1">{{ result.callsign || "N/A" }}</span>
              <span>{{ result.shipname || "N/A" }}</span>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center">
          <component :is="getIcon('STATION')" class="w-10 h-10" />
          <div class="flex flex-col pl-3">
            <div class="text-base text-gray-900">{{ result.id }}</div>
            <div class="flex text-xs text-gray-500">
              <span class="mr-1">{{ result.city || "N/A" }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FilterIcon from "../assets/icons/filter.svg";
import { alphanum } from "../utils/alphanum";

export default {
  name: "SearchBar",
  components: {
    FilterIcon,
  },
  computed: {
    ...mapState(["flights", "ships", "stations", "selected", "map"]),
    isSearching() {
      return this.search !== "";
    },
    isResultFound() {
      return this.results.length > 0;
    },
  },
  data() {
    return {
      search: "",
      results: [],
      filter: false,
    };
  },
  watch: {
    flights() {
      this.isSearching ? this.filterJson() : null;
    },
  },
  methods: {
    ...mapMutations([
      "setCamera",
      "setSelected",
      "removeSelected",
      "setFilter",
      "removeFilter",
    ]),
    filterJson() {
      let pattern = new RegExp("^" + this.search, "gi");
      console.log((this.results = [...Object.values(this.ships)]));
      this.results = [
        ...Object.values(this.flights),
        ...Object.values(this.stations),
        ...Object.values(this.ships),
      ]
        .filter(
          (feature) =>
            pattern.test(feature.id) ||
            pattern.test(feature.callsign) ||
            pattern.test(feature.reg) ||
            pattern.test(feature.icon) ||
            pattern.test(feature.usage) ||
            pattern.test(feature.typeIcao) ||
            pattern.test(feature.opIcao) ||
            pattern.test(feature.fromIcao) ||
            pattern.test(feature.toIcao) ||
            pattern.test(feature.shipname)
        )
        .sort((a, b) => alphanum(a.id, b.id));
    },
    filterMap() {
      if (this.isSearching) {
        let ids = this.results.map((res) => res.id);
        this.setFilter(["in", ["get", "id"], ["literal", ids]]);
        this.removeSelected();
      }
    },
    onSearch(event) {
      this.search = event.target.value;
      if (this.search !== "") {
        this.filterJson();
        this.filter ? this.filterMap() : null;
      } else {
        this.results = [];
        this.filter = false;
        this.removeFilter();
      }
    },
    onFilter() {
      this.filter = !this.filter;
      if (this.filter) {
        this.filterMap();
      } else {
        this.removeFilter();
      }
    },
    onClick(result) {
      this.filter = false;
      this.removeFilter();
      this.setSelected({ id: result.id, layer: result.layer });
      this.$router.replace({
        path: `/${result.layer}/${result.id}`,
      });
      this.setCamera({
        longitude: result.coordinates[0],
        latitude: result.coordinates[1],
        zoom: 12,
        pitch: 0,
        bearing: 0,
      });
      this.search = "";
      this.results = [];
    },
    getIcon(icon) {
      return require(`../assets/markers/${icon}.svg`);
    },
  },
};
</script>
