/**
 * Convert feets to meters
 *
 * @param {Number} value    The value to convert (in feet)
 * @param {Number} decimals The number of decimals to keep. Default to 2
 * @returns {Number}        The value converted (in meters)
 */
export function feetToMeters(value, decimals = 2) {
  return (value / 3.2808).toFixed(decimals);
}

/**
 * Convert kt to km/h
 *
 * @param {Number} value    The value to convert (in kt)
 * @param {Number} decimals The number of decimals to keep. Default to 2
 * @returns {Number}        The value converted (in km/h)
 */
export function knotsToKilometersPerHour(value, decimals = 2) {
  return (value / 1.9438).toFixed(decimals);
}

/**
 * Convert kt to mph
 *
 * @param {Number} value    The value to convert (in kt)
 * @param {Number} decimals The number of decimals to keep. Default to 2
 * @returns {Number}        The value converted (in mph)
 */
export function knotsToMilesPerHour(value, decimals = 2) {
  return (value * 1.15078).toFixed(decimals);
}

/**
 * Convert ft/min to m/s
 *
 * @param {Number} value    The value to convert (in ft/min)
 * @param {Number} decimals The number of decimals to keep. Default to 2
 * @returns {Number}        The value converted (in m/s)
 */
export function feetPerMinToMetersPerSecond(value, decimals = 2) {
  return (value / 196.85).toFixed(decimals);
}

/**
 *  Convert a timestamp to a HH:MM:SS formatted date
 *
 * @param {Number} timestamp Timestamp value in seconds
 * @returns {String}         Date in HH:MM:SS
 */
export function timestampToDate(timestamp) {
  let date = new Date(timestamp);
  let hours = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
  let minutes =
    date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;
  let seconds =
    date.getSeconds() >= 10 ? date.getSeconds() : `0${date.getSeconds()}`;
  return `${hours}:${minutes}:${seconds}`;
}
