<template>
  <MapboxSource
    :sourceId="id"
    type="geojson"
    :tolerance="0"
    promoteId="id"
    :data="positions"
  >
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="circle"
      beforeId="ships"
      :minzoom="8"
      :layout="layout"
      :paint="paint"
    />
  </MapboxSource>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PositionLayer",
  computed: {
    ...mapGetters(["positions"]),
  },
  data() {
    return {
      id: "position",
      layout: {
        "circle-sort-key": ["get", "altitude"],
      },
      paint: {
        "circle-opacity": 0.9,
        "circle-color": [
          "interpolate",
          ["linear"],
          ["get", "altitude"],
          0,
          "#4242ff",
          5000,
          "#42ffd0",
          10000,
          "#a1ff42",
          15000,
          "#ff7142",
          20000,
          "#ff42ff",
        ],
        "circle-radius": 2,
        "circle-stroke-color": "#ffffff",
        "circle-stroke-width": 1,
      },
    };
  },
};
</script>

<style></style>
