<template>
  <div class="flex items-center">
    <span class="mr-2">{{this.hovered.id}}</span>
    <span class="flex items-center">
      <RadarIcon class="w-4 mr-1" />
      {{this.flights}}
    </span>
  </div>
</template>

<script>
import RadarIcon from "../assets/icons/radar.svg";

export default {
  name: "StationTooltip",
  components: {
    RadarIcon,
  },
  props: {
    hovered: {
      type: Object,
      required: true,
    },
  },
  computed: {
    flights() {
      return this.hovered.flights.length;
    },
  },
};
</script>

<style>
</style>