import circle from "@turf/circle";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

/**
 * Transform an array into an object indexed by a key
 * @param {Array} array input array
 * @param {string} key key used as index
 * @returns {Object} normalized data
 */
export function normalize(array, key) {
  return array.reduce((obj, item) => ((obj[[item[key]]] = item), obj), {});
}

/**
 * Transform an object indexed by a key into an array
 * @param {Object} array input object
 * @returns {Array} denormalized data
 */
export function denormalize(object) {
  return Object.values(object);
}

/**
 * Format the aircraft's icon field or this field is null choose a default icon based on the category
 * @param {string} icon original icon name
 * @param {string} category category of the aircraft
 * @param {Array} icons list of the available icons
 * @returns {string} icon name
 */
export function iconSelector(icon, category, icons) {
  if (icon && icons.includes(icon.toUpperCase().trim())) {
    return icon.toUpperCase().trim();
  } else if (category) {
    switch (category) {
      case "A0": // Unknown plane
        return "GASP";
      case "A1": // Light
        return "GASP";
      case "A2": // Small
        return "ALMTBF2";
      case "A3": // Large
        return "ALMTBF2";
      case "A4": // High Vortex Large
        return "ALTBF2";
      case "A5": // Heavy
        return "ALTBF2";
      case "A6": // High Performance
        return "FIGHT-F22";
      case "A7": // Rotor-craft
        return "HLCPT";
      case "B1": // Glider
        return "GLID";
      case "B2": // Lighter-than-Air
        return "BALOON";
      case "B3": // Skydiver
        return "PARAGLIDER";
      case "B4": // ULM / Hang Glider / Paraglider
        return "ULM";
      case "B6": // Unmanned Aerial Vehicle
        return "UAV-PREDATOR";
      case "B7": // Space/Trans-Atmospheric Vehicle
        return "UNDEFINED";
      case "C1": // Emergency Vehicle
        return "VSOL";
      case "C2": // Service Vehicle
        return "VSOL";
      case "M": // Unknown plane
        return "VSOL";
      case "BOAT": // Unknown plane
        return "BOAT";
      default:
        return "UNDEFINED";
    }
  } else {
    return "UNDEFINED";
  }
}

/**
 * Create a circle with the start position as center and determine if the end position is within
 * @param {Array} start start position in [Lng, Lat] format
 * @param {Array} end end position in [Lng, Lat] format
 * @param {number} radius radius size, default: 0.5
 * @param {string} units radius unit (miles|kilometers|degrees|radians), default: degrees
 */
export function isPositionValid(start, end, radius = 0.5, units = "degrees") {
  return booleanPointInPolygon(
    end,
    circle(start, radius, {
      units,
    })
  );
}
