import distance from '@turf/distance';

/**
 * Check if a coordinates is inside a bounding box
 *
 * @param {Object} bbox   The bounding box
 * @param {Object} coords The coordinates
 * @returns {Boolean}
 */
export function inBbox(bbox, coords) {
  if (
    coords[1] <= bbox.ne.lat &&
    coords[1] >= bbox.sw.lat &&
    coords[0] <= bbox.ne.lng &&
    coords[0] >= bbox.sw.lng
  ) {
    return true;
  } else {
    return false;
  }
}
/**
 * Sort a list of features by its distance to a point
 *
 * @param {Array} features The feature list to sort
 * @param {Array} coords   The coordinates a the reference point
 * @returns {Array}        The sorted list
 */
export function sortByDistance(features, coords) {
  return features.sort((a, b) => {
    if (distance(a.geometry.coordinates, coords) < distance(b.geometry.coordinates, coords))
      return -1;
    if (distance(b.geometry.coordinates, coords) < distance(a.geometry.coordinates, coords))
      return 1;
    return 0;
  });
}

/*
 * Adapted from the example by Mike Bostock
 * http://bl.ocks.org/mbostock/4597134
 */

let pi = Math.PI,
    radians = pi / 180,
    degrees = 180 / pi;

export function antipode(position) {
  return [position[0] + 180, -position[1]];
}

export function sun(time) {
  var centuries = (time - Date.UTC(2000, 0, 1, 12)) / 864e5 / 36525, // since J2000
      longitude = (new Date(+time).setUTCHours(0, 0, 0, 0) - time) / 864e5 * 360 - 180;
  return [
      longitude - equationOfTime(centuries) * degrees,
      solarDeclination(centuries) * degrees
  ];
}

// Equations based on NOAA’s Solar Calculator; all angles in radians.
// http://www.esrl.noaa.gov/gmd/grad/solcalc/

function equationOfTime(centuries) {
  var e = eccentricityEarthOrbit(centuries),
      m = solarGeometricMeanAnomaly(centuries),
      l = solarGeometricMeanLongitude(centuries),
      y = Math.tan(obliquityCorrection(centuries) / 2);
      y *= y;
  return y * Math.sin(2 * l) -
      2 * e * Math.sin(m) +
      4 * e * y * Math.sin(m) * Math.cos(2 * l) -
      0.5 * y * y * Math.sin(4 * l) -
      1.25 * e * e * Math.sin(2 * m);
}

function solarDeclination(centuries) {
  return Math.asin(Math.sin(obliquityCorrection(centuries)) * Math.sin(solarApparentLongitude(centuries)));
}

function solarApparentLongitude(centuries) {
  return solarTrueLongitude(centuries) - (0.00569 + 0.00478 * Math.sin((125.04 - 1934.136 * centuries) * radians)) * radians;
}

function solarTrueLongitude(centuries) {
  return solarGeometricMeanLongitude(centuries) + solarEquationOfCenter(centuries);
}

function solarGeometricMeanAnomaly(centuries) {
  return (357.52911 + centuries * (35999.05029 - 0.0001537 * centuries)) * radians;
}

function solarGeometricMeanLongitude(centuries) {
  var l = (280.46646 + centuries * (36000.76983 + centuries * 0.0003032)) % 360;
  return (l < 0 ? l + 360 : l) / 180 * pi;
}

function solarEquationOfCenter(centuries) {
  var m = solarGeometricMeanAnomaly(centuries);
  return (Math.sin(m) * (1.914602 - centuries * (0.004817 + 0.000014 * centuries)) +
          Math.sin(m + m) * (0.019993 - 0.000101 * centuries) +
          Math.sin(m + m + m) * 0.000289) * radians;
}

function obliquityCorrection(centuries) {
  return meanObliquityOfEcliptic(centuries) + 0.00256 * Math.cos((125.04 - 1934.136 * centuries) * radians) * radians;
}

function meanObliquityOfEcliptic(centuries) {
  return (23 + (26 + (21.448 - centuries * (46.8150 + centuries * (0.00059 - centuries * 0.001813))) / 60) / 60) * radians;
}

function eccentricityEarthOrbit(centuries) {
  return 0.016708634 - centuries * (0.000042037 + 0.0000001267 * centuries);
}