<template>
  <MapboxSource :sourceId="id" type="geojson" promoteId="id" :data="stations">
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="symbol"
      :minzoom="5"
      :layout="layout"
      :paint="paint"
      beforeId="flights"
    />
    <MapboxLayer
      layerId="pulse-station"
      :sourceId="id"
      type="symbol"
      :minzoom="5"
      :filter="['in', ['get', 'id'], ['literal', pulse]]"
      :layout="{
        'icon-allow-overlap': true,
        'icon-image': 'pulse',
      }"
      :beforeId="id"
    />
    <FeatureState
      v-if="isSelected"
      :featureId="selected.id"
      :source="id"
      :state="{select: true}"
    />
    <FeatureState
      v-if="isHovered"
      :featureId="hovered.id"
      :source="id"
      :state="{hover: true}"
    />
  </MapboxSource>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";

export default {
  name: "StationLayer",
  computed: {
    ...mapState(["filter", "hovered", "selected", "pulse"]),
    ...mapGetters(["stations", "isSelected", "isHovered"]),
  },
  data() {
    return {
      id: "stations",
      layers: ["stations"],
      layout: {
        "icon-image": "STATION",
        "symbol-sort-key": ["to-number", ["get", "altitude"]],
        "symbol-z-order": "auto",
        "icon-allow-overlap": true,
        "icon-size": 1.5,
        "icon-padding": 0,
      },
      paint: {
        "icon-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "select"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          "#f1c40f",
          "#2ecc71",
        ],
        "icon-halo-color": "#fff",
        "icon-halo-width": 2,
      },
      loop: null,
    };
  },
  async mounted() {
    await this.fetchStations();
    this.$emit("loaded");
    this.loop = setInterval(async () => {
      await this.fetchStations();
    }, 1000 * 60 * 5);
  },
  destroyed() {
    clearInterval(this.loop);
  },
  methods: {
    ...mapActions(["fetchStations"]),
  },
};
</script>

<style></style>
