export default {
  size: 15,
  scale: 8,
  width: 15 * 8,
  height: 15 * 8,
  duration: 1000,
  data: new Uint8Array(15 * 8 * 15 * 8 * 4),

  onAdd(map) {
    var canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.map = map;
    this.context = canvas.getContext('2d');
    this.body = new Path2D(
      `M7.5 4.87391836c.7437555 0 .72606618 1.2744351.72606618 1.2744351s0 1.38057102.03457458 2.76114204c.0361827 1.38137508-.49530096 1.59364692-.49530096 1.59364692l-.03537864.17769726v1.6282215h.99140598c.1768932 0 .1768932.07075728.1768932.07075728l-.07075728.7437555-.07075728-.28383318H7.72996116v1.80591876l-.07075728.2130759-.21227184.14151456v-.14151456l-.1768932-.35459046V12.8051662H6.24325422l-.07075728.28302912-.07075728-.7437555s0-.07075728.1768932-.07075728h.99140598l-.03537864-1.62902556-.03537864-.17608914s-.53148366-.2130759-.53148366-1.55907234V6.14835346s.01929744-1.23986052.7276743-1.2744351H7.5z`
    );
    this.blade = new Path2D(
      `M7.8973471 7.72061564s3.40001112 3.22394337 3.70931934 3.5324585c.30851513.30930821-.13244736.92792465-.13244736.92792465L7.80931322 8.6485403l-.08803388-.7954773c-.08882697.0444135-.13244736.08882698-.26568783.08882698-.08803388 0-.13244736 0-.22048124-.04441349 0 0-3.22394337 3.35559764-3.5324585 3.70931935-.22127434.26489473-.83909768-.17686085-.83909768-.17686085l3.5324585-3.66490586.7510638-.08803388c-.04441349-.04441349-.08882698-.13244737-.08882698-.22127434 0-.08803388 0-.13244737.0444135-.22127434 0 0-3.40001113-3.22315028-3.70931935-3.5324585-.30851513-.30851512.13244736-.88271807.13244736-.88271807l3.66490586 3.5324585.08803388.7510638c.08882697-.04441349.17686085-.08803388.26568783-.08803388.08803388 0 .13244736 0 .17686085.04362039 0 0 3.22315027-3.35639074 3.5316654-3.70931935.30930822-.3529286.88351117.13244737.88351117.13244737L8.6039974 7.14641269l-.7510638.08803388c.08882698.04441348.08882698.13324046.08882698.22127434 0 .08882697 0 .17686085-.0444135.26489473`
    );
  },
  render() {
    var t = ((performance.now() % 1000) * 360) / this.duration;
    var ctx = this.context;
    ctx.clearRect(0, 0, this.width, this.height);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(this.scale, this.scale);
    ctx.fill(this.body);
    ctx.translate(this.size / 2, this.size / 2);
    ctx.rotate((t * Math.PI) / 180);
    ctx.translate(-(this.size / 2), -(this.size / 2));
    ctx.fill(this.blade);
    this.data = ctx.getImageData(0, 0, this.width, this.height).data;
    this.map.triggerRepaint();
    return true;
  },
};
