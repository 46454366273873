<template>
  <div
    class="absolute z-10 p-2 m-3 font-mono text-white bg-black rounded text-xxs"
    :style="{ left: coordinates.x + 'px', top: coordinates.y + 'px' }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "GeoTooltip",
  props: {
    coordinates: {
      type: Object,
      required: true,
    },
  },
};
</script>
