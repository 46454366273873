<template>
  <div class="absolute top-0 left-0 z-30 w-full h-full">
    <div
      ref="background"
      class="flex items-center justify-center w-full h-full bg-black bg-opacity-75"
      @click="onClick($event)"
    >
      <div
        class="px-4 text-left bg-white rounded sm:my-8 sm:max-w-lg sm:w-full sm:p-6 sm:pb-4"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <CloseIcon
          class="float-right cursor-pointer"
          @click="onClose($event)"
        />
        <h3 class="pb-4 text-lg font-medium leading-6 text-gray-900">
          {{ this.layer }}
          <span class="text-blue-500">{{ this.id }}</span> Not Found
        </h3>
        <p class="text-sm leading-5 text-gray-500">
          The flight you are looking for is either non-existent or untracked and
          therefore not displayed on this map.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { capitalizeFirstLetter } from "../utils/strings";
import CloseIcon from "../assets/icons/close.svg";

export default {
  name: "Modal",
  components: {
    CloseIcon,
  },
  computed: {
    ...mapState(["camera"]),
    layer() {
      return capitalizeFirstLetter(this.$route.name).slice(0, -1);
    },
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapMutations(["toggleModal"]),
    onClick(event) {
      if (event.target === this.$refs.background) {
        this.onClose();
      }
    },
    onClose() {
      this.toggleModal(false);
      this.$router.replace({
        path: `/${this.camera.zoom.toFixed(2)}/${this.camera.latitude.toFixed(
          2
        )}/${this.camera.longitude.toFixed(2)}`,
      });
    },
  },
};
</script>
