<template>
  <div class="flex items-center">
    <span class="flex items-center">
      <SpeedometerIcon class="w-4 mr-1" />
      {{ this.hovered.speed.toFixed(2) || "N/A" }} kts
    </span>
  </div>
</template>

<script>
import SpeedometerIcon from "../assets/icons/speedometer.svg";
export default {
  name: "ShipTooltip",
  components: {
    SpeedometerIcon,
  },
  props: {
    hovered: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
