import { render, staticRenderFns } from "./ShipTooltip.vue?vue&type=template&id=6bf3019e&"
import script from "./ShipTooltip.vue?vue&type=script&lang=js&"
export * from "./ShipTooltip.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports