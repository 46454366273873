<template>
  <MapboxSource :sourceId="id" type="geojson" :tolerance="0" :data="path">
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="line"
      beforeId="ships"
      :layout="layout"
      :paint="paint"
    />
  </MapboxSource>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "PathLayer",
  computed: {
    ...mapState(["selected"]),
    ...mapGetters(["path"]),
  },
  data() {
    return {
      id: "path",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-opacity": 0.9,
        "line-color": [
          "interpolate",
          ["linear"],
          ["get", "altitude"],
          0,
          "#4242ff",
          5000,
          "#42ffd0",
          10000,
          "#a1ff42",
          15000,
          "#ff7142",
          20000,
          "#ff42ff",
        ],
        "line-width": 3,
      },
    };
  },
  async mounted() {
    if (this.selected.layer === "flights") {
      await this.fetchPath(this.selected.id);
    } else if (this.selected.layer === "ships") {
      await this.fetchCruise(this.selected.id);
    }
  },
  watch: {
    async selected(newProp, oldProp) {
      if (newProp.id !== oldProp.id) {
        if (this.selected.layer === "flights") {
          await this.fetchPath(this.selected.id);
        } else if (this.selected.layer === "ships") {
          await this.fetchCruise(this.selected.id);
        }
      }
    },
  },
  methods: {
    ...mapActions(["fetchPath", "fetchCruise"]),
  },
};
</script>
