export default {
  size: 75,
  height: 75,
  width: 75,
  duration: 2000,
  data: new Uint8Array(75 * 75 * 4),
  onAdd(map) {
    var canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext('2d');
    this.map = map;
  },
  render() {
    var t = (performance.now() % this.duration) / this.duration;
    var radius = (this.size / 2) * 0.3;
    var outerRadius = (this.size / 2) * 0.4 * t + radius;
    var ctx = this.context;
    ctx.clearRect(0, 0, this.width, this.height);
    ctx.beginPath();
    ctx.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
    ctx.fillStyle = 'rgba(' + '149,165,166' + ',' + (0.75 - t) + ')'; 
    ctx.fill();
    this.data = ctx.getImageData(0, 0, this.width, this.height).data;
    this.map.triggerRepaint();
    return true;
  },
};
