<template>
  <MapboxSource
    :sourceId="id"
    type="geojson"
    promoteId="id"
    attribution="&copy; ADS-B Network"
    :data="ships"
  >
    <MapboxLayer
      :layerId="id"
      :sourceId="id"
      type="symbol"
      beforeId="flights"
      :layout="iconLayout"
      :paint="iconPaint"
    />
    <MapboxLayer
      layerId="label-ship"
      :sourceId="id"
      type="symbol"
      :minzoom="8"
      :layout="labelLayout"
      :paint="labelPaint"
    />
    <FeatureState
      v-if="isSelected"
      :featureId="selected.id"
      :source="id"
      :state="{ select: true }"
    />
    <FeatureState
      v-if="isHovered"
      :featureId="hovered.id"
      :source="id"
      :state="{ hover: true }"
    />
  </MapboxSource>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ShipLayer",
  computed: {
    ...mapState(["filter", "hovered", "selected"]),
    ...mapGetters(["ships", "isSelected", "isHovered"]),
  },
  data() {
    return {
      id: "ships",
      layers: ["ships"],
      iconLayout: {
        "icon-image": "BOAT",
        "icon-allow-overlap": true,
        "symbol-sort-key": ["to-number", ["get", "course"]],
        "icon-size": ["interpolate", ["linear"], ["zoom"], 0, 0.3, 24, 3],
        "icon-rotate": ["to-number", ["get", "course"]],
        "icon-pitch-alignment": "map",
        "icon-rotation-alignment": "map",
        "icon-keep-upright": false,
      },
      iconPaint: {
        "icon-color": [
          "case",
          [
            "any",
            ["boolean", ["feature-state", "select"], false],
            ["boolean", ["feature-state", "hover"], false],
          ],
          "#E74C3C",
          "#000080",
        ],
        "icon-halo-color": "#ffffff",
        "icon-halo-width": 2,
      },
      labelLayout: {
        "text-anchor": "top-left",
        "text-field": [
          "format",
          ["coalesce", ["get", "shipname"], "N/A"],
          { "font-scale": 1 },
          "\n",
          ["coalesce", ["get", "callsign"], "N/A"],
          { "font-scale": 0.8 },
          "\n",
        ],
        "text-justify": "auto",
        "text-offset": [1, 1],
        "text-font": ["Open Sans Bold"],
        "text-size": 16,
        "text-pitch-alignment": "viewport",
      },
      labelPaint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 1.5,
      },
      loop: null,
    };
  },
  //   watch: {
  //     bbox: {
  //       async handler() {
  //         await this.fetchShips();
  //       },
  //       deep: true,
  //     },
  //   },
  async mounted() {
    await this.fetchShips();
    this.$emit("loaded");
    this.loop = setInterval(async () => {
      await this.fetchShips();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.loop);
  },
  methods: {
    ...mapActions(["fetchShips"]),
  },
};
</script>
