<template>
  <Navbar />
</template>

<script>
import Navbar from "./Navbar";

export default {
  name: "Home",
  components: {
    Navbar,
  },
};
</script>

<style>
</style>